.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 40px;
  height: 20px;
  background: white;
  border-radius: 100px;
  position: relative;
  border: 2px solid #115622;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 14px;
  height: 14px;
  border-radius: 45px;
  transition: 0.1s;
  background: #115622;
  box-shadow: 0 0 2px 0 #115622;
}

.blocked .switch-button {
  background-color: #fff;
}
.blocked {
  border: 2px solid lightgrey;
  cursor: default;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
  background: white;
}

.switch-label:active .switch-button {
  width: 15px;
}
