.link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-evenly;
  margin: 0 auto;
  padding-bottom: 40px;
  color: grey;
  text-align: center;
}

.link:hover {
  text-decoration: none;
  color: grey;
}
.footer-menu {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-evenly;
  max-width: 800px;
  min-width: fit-content;
  margin: 0 auto;
  padding-bottom: 40px;
}
.footer-menu .link {
 padding: 0px 5px 10px 5px;
}
@media (max-width: 768px) {
  .footer-menu {
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .link {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .footer-menu {
    flex-direction: column;
  }
}
