.agreement-container {
  position: relative;
  padding: 0 40px 0 15px !important;
}
@media (max-width: 575px) {
  .agreement-container {
    padding: 0 !important;
    left: -10px;
  }
}

.newsletter-label {
  padding-left: 36px;
  text-align: left;
  font-size: 13px;
}
.form-group {
  margin-bottom: 0.4rem;
}
.form-check-label {
  margin-left: 3px;
}
.form-check-label,
.card-body,
.newsletter-radio {
  font-size: 13px;
}
.form-check-input {
  cursor: pointer;
}
.form-control {
  font-size: 12px;
  margin: auto 10px;
}
.card-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
.roll::after {
  content: "Zwiń.";
}
.roll.collapsed::after {
  content: "Zobacz pełną treść.";
}
.invalid-feedback {
  position: relative;
  left: 25px;
}

.bottle-col-1 {
  /*  background-color: rgb(91, 115, 107); */
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.bottle-col-2 {
  /*   background-color: rgb(57, 97, 85); */
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.bottle-col-3 {
  /*  background-color: rgb(94, 107, 107); */
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.bottle-img {
  height: 220px;
  position: relative;
  top: -25px;
  transition: 0.6s;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.8));
}
.bottle-img:hover {
  top: -45px;
  cursor: pointer;
  filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.9));
}
.beer-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.beer-content {
  color: white;
  font-size: 13px;
  line-height: 10px;
}
.form-control:disabled {
  background-color: rgb(241, 241, 241);
}
.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.search-select {
  padding: 0;
  border-radius: 0;
}
span[class*="indicatorSeparator"] {
  opacity: 0;
}
div[class*="indicatorContainer"] {
  position: relative;
  top: -2px;
}
.acceptLabel {
  margin-top: 2px;
}
.custom-alert-box {
  position: absolute;
  background-color: white;
  border: 1px solid #898989;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 11px;
  z-index: 1;
  text-align: center;
  max-width: 325px;
}
.alert-bottom {
  left: 43%;
  top: 30px;
}
.alert-bottom .custom-alert-arrow {
  top: -5px;
  border-top: 1px solid #898989;
  border-left: 1px solid #898989;
}
.alert-top {
  left: 38%;
  top: -30px;
}
.alert-top .custom-alert-arrow {
  bottom: -5px;
  border-bottom: 1px solid #898989;
  border-right: 1px solid #898989;
}
.custom-alert-arrow {
  width: 8px;
  height: 8px;
  display: inline-block;
  position: absolute;
  background: white;
  left: 49%;
  transform: rotate(45deg);
}
.custom-alert-icon {
  background-color: rgb(255, 163, 0);
  width: 19px;
  height: 19px;
  text-align: center;
  font-weight: 900;
  color: white;
  border-radius: 2px;
  margin-right: 6px;
  display: inline-block;
  font-size: 13px;
}
.group-relative {
  position: relative;
}
