.bottle-col-1 {
/*     background-color: rgb(91, 115, 107); */
    min-height: 100px;
    display: flex;
    justify-content: center;
}
.bottle-col-2 {
    /* background-color: rgb(57, 97, 85); */
    min-height: 100px;
    display: flex;
    justify-content: center;
}
.bottle-col-3 {
    /* background-color: rgb(94, 107, 107); */
    min-height: 100px;
    display: flex;
    justify-content: center;
}
.bottle-img {
    height: 220px;
    position: relative;
    top: -25px;
    transition: .6s;
    filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.8));
}
.bottle-img:hover{
    top: -45px;
    cursor: pointer;
    filter: drop-shadow(2px 4px 8px rgba(0,0,0,0.9));
}
.beer-desc {
   
}
.beer-title {
    color: white;
    font-size: 16px;
    font-weight: 600;
}
.beer-content{
    color: white;
    font-size: 13px;
    line-height: 10px;
}