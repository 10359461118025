.cookies-container {
  padding: 0;
  margin: 0;
  background-color: rgba(86, 86, 86, 0.25);

  height: 100%;
  min-height: fit-content;

  width: 100%;

  color: #115622;

  border-radius: 0;
  -webkit-transition: width 0.2s ease-in-out;
  transition: width 0.2s ease-in-out;
  text-align: left;

  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  z-index: 1021;
  overflow: hidden;
  overflow-y: auto;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cookies-content {
  margin-top: 0;
  min-height: fit-content;
  box-shadow: 0 6px 10px 0 rgba(60, 64, 70, 0.6);
  -webkit-box-shadow: 0 6px 10px 0 rgba(60, 64, 70, 0.6);
  padding: 80px 40px;
  max-width: 850px;
  max-height: 100%;
  overflow-y: auto;
  background-color: white;
}

.info-container {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;
}

.info-container .cookies-settings-title {
  color: #115622;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  font-weight: 900;
  margin-bottom: 25px;
  line-height: 1.25 !important;
}

.info-container .text-container {
  color: #363a3f;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  text-transform: none;
  text-shadow: none;
  box-sizing: border-box;
}

.info-container .text-container .text-link {
  color: #363a3f;
  text-decoration: underline;
  margin-left: 2px;
  margin-right: 2px;
}

.text {
  display: inline-block;
  margin-bottom: 10px;
}

.details-for-start {
  display: inline;
}

.details-for-switch {
  color: #363a3f;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
}

.details-container .show-more {
  display: flex;
  flex-direction: row;
}

.details-container .details {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  color: #363a3f;
  font-family: "Montserrat", sans-serif;
}

.details-container .show-more .details-text {
  margin: 0;
  padding: 0;
  color: #363a3f;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0;
  text-decoration: underline;
}

.details-container .hidden {
  display: none;
}

.details-container .icon {
  display: block;
  background-image: url("../../images/arrow.svg");
  background-size: contain;
  width: 18px;
  height: 18px;
}

.details-container .reversed {
  transform: rotate(180deg);
}

.settings-container {
  padding: 20px 0;
}

.details-for-switch[open] {
  width: 90%;
  margin-left: auto;
}

.buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 55px;
}

.buttons .button {
  border: 2px solid #115622;
}
.save {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-right: 25px;
  padding: 10px 55px;
  font-size: 20px;
  background-color: #fff;
}
.accept {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 10px 55px;
  background-color: #115622;
  color: #ffffff;
  font-size: 20px;
}

.label-cookies {
  margin-left: 10px;
  font-weight: 800;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}
.summary-cookies {
  font-size: 15px;
  font-weight: 400 !important;
  text-decoration: underline;
  cursor: pointer;
}

.switch-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-bottom: 31px;
  position: relative;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

.switch-with-label {
  display: flex;
}

@media (max-width: 768px) {
  .cookies-content {
    height: 100%;
    padding: 30px;
  }
  .info-container .cookies-settings-title {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 1.25 !important;
  }
  .info-container .text-container {
    font-size: 14px;
  }

  .settings-container {
    padding: 15px 0;
  }
  .buttons {
    margin-top: 30px;
  }
  .save {
    margin-right: 15px;
    padding: 8px 35px;
    font-size: 16px;
  }
  .accept {
    padding: 8px 35px;
    font-size: 16px;
  }
  .label-cookies {
    margin-left: 8px;
    font-size: 14px;
  }
  .summary-cookies {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .cookies-content {
    padding: 20px;
  }
  .info-container .cookies-settings-title {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 1.25 !important;
  }
  .info-container .text-container {
    font-size: 14px;
  }

  .settings-container {
    padding: 14px 0;
  }
  .buttons {
    margin-top: 30px;
  }
  .save {
    margin-right: 10px;
    padding: 6px 30px;
    font-size: 14px;
  }
  .accept {
    padding: 6px 30px;
    font-size: 14px;
  }
  .label-cookies {
    margin-left: 8px;
    font-size: 14px;
  }
  .summary-cookies {
    font-size: 14px;
  }
}
